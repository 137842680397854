import { environment } from '@env/environment';

import { enableProdMode } from '@angular/core';

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { DocsModule } from './app/docs.module';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(DocsModule);
