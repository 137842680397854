import { Component } from '@angular/core';

import iconsFullMetadata from '../../../../../src/assets/icons/selection-full.json';

import iconsFreeMetadata from '../../../../../src/assets/icons/selection-free.json';

@Component({
  selector: 'zef-icons',
  templateUrl: './icons.component.html',
  styleUrls: ['../docs.component.scss'],
})
export class ZefIcons {
  iconsFull = iconsFullMetadata.icons.map(({ properties: { name, ligatures } }) => ({ name, ligatures }));
  iconsFree = iconsFreeMetadata.icons.map(({ properties: { name, ligatures } }) => ({ name, ligatures }));
}
