import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ZefIcons } from 'projects/docs/src/app/+icons/icons.component';
import { IndexPage } from 'projects/docs/src/app/+index/index.component';

export const DocsRoutes: Routes = [
  { path: '', component: IndexPage },
  { path: 'linda', loadChildren: () => import('./+linda/linda.module').then((m) => m.LindaDocsModule) },
  { path: 'linda2', loadChildren: () => import('./+linda/linda2.module').then((m) => m.Linda2Module) },
  { path: 'icons', component: ZefIcons },
];

export const DocsRouting: ModuleWithProviders<RouterModule> = RouterModule.forRoot(DocsRoutes, {
  paramsInheritanceStrategy: 'always',
  enableTracing: false,
});
