import { APP_BASE_HREF } from '@angular/common';
import { Injector, NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { MatIconRegistry } from '@angular/material/icon';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from '@env/environment';
import { PaymentFailed } from '@home/shared/dialogs/payment-failed/payment-failed.dialog';
import { TrialExpired } from '@home/shared/dialogs/trial-expired/trial-expired.dialog';
import { NgxsModule } from '@ngxs/store';
import { SharedModule } from '@shared/shared.module';
import { ZefIcons } from 'projects/docs/src/app/+icons/icons.component';
import { IndexPage } from 'projects/docs/src/app/+index/index.component';
import { DocsApp } from 'projects/docs/src/app/docs.component';
import { DocsRouting } from 'projects/docs/src/app/docs.routing';

@NgModule({
  bootstrap: [DocsApp],
  declarations: [DocsApp, ZefIcons, IndexPage, PaymentFailed, TrialExpired],
  exports: [],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    DocsRouting,
    SharedModule,
    NgxsModule.forRoot([]),
    AngularFireDatabaseModule,
    AngularFireModule.initializeApp(environment.firebase, 'zefApp'),
  ],
  providers: [
    {
      provide: APP_BASE_HREF,
      useValue: '/',
    },
  ],
})
export class DocsModule {
  constructor(readonly injector: Injector, readonly registry: MatIconRegistry) {
    registry.registerFontClassAlias('zeficons', 'zef-icons-full');
    registry.setDefaultFontSetClass('zef-icons-full');
  }
}
