<div class="wrapper z-fx-col z-fx-gap-24">
  <h2>zeff-icons-free</h2>

  <div class="grid grid-4">
    <div *ngFor="let icon of iconsFree" class="icon z-fx z-fx-gap-8 z-fx-between-center">
      <mat-icon fontSet="zefplayer">{{ icon.ligatures }}</mat-icon>
      <span class="zef-text-p1">{{ icon.name }}</span>
    </div>
  </div>

  <h2>zeff-icons-full</h2>

  <div class="grid grid-4">
    <div *ngFor="let icon of iconsFull" class="icon z-fx z-fx-gap-8 z-fx-between-center">
      <mat-icon>{{ icon.ligatures }}</mat-icon>
      <span class="zef-text-p1">{{ icon.name }}</span>
    </div>
  </div>
</div>
